@import '../../css/essentials/all';

.Loader {
  position: relative;
  
  &.is-centered {
    background: rgba($white, 0.2);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
     left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.has-error {
    background: $red;
  }

  &:not(.has-no-spinner) {
    .Spinner {
      @include loader();
    }
  }
}

