@import '../../css/essentials/all';

.GraphStats {
  margin-top: $gutter/1.5;
  margin-bottom: -$gutter/1.5;
  padding-top: $gutter/2;
  border-top: 1px solid $border-color;

  .GraphStatTitle {
    color: $text-color-light;
  }
}

// CALENDAR

.Graph {
  
}

.CalendarGraph {
  margin-top: 0-$size-small;
  .level-item:not(:last-child) {
    padding-right: $gutter; 
  }

  .Month {
    display: grid;
    user-select: none;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr;
    margin: -2px {
      top: 0;
    }

    &Title {
     color: $grey; 
     margin-bottom: $size-micro;
    }

    &:before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    & > *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  }
  
  .Day {
    flex: none;
    padding: 2px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: inline-block;

    .DayContent {
      outline: none;
      background: $grey-lighter;
      width: 100%;
      height: 100%;
      border-radius: $radius-tiny;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include transition();
      position: relative;
    }

    &.is-today {
      .DayContent {
        background-color: $grey-light;
        box-shadow: 0px 0px 0px 2px rgba($grey-light, 1), inset 0px 0px 0px 1px $white, 0px 0px 0px 3px $white;
      }
    }
    &.is-planned {
      // .DayContent {
      //   background-color: rgba($blue, 0.4);
      // }
    }

    &:hover {
      z-index: 2;
      .DayContent {
        background: $grey;
        // transform: scale(1.2);
      }
    }

    &:active {
      .DayContent {
        transform: scale(1.1);
        background: $dark;
      }
    }

    

    &.is-success {
      .DayContent {
        background-color: $blue;

        &:hover {
          background-color: $blue-dark;
        }
        &:active {
          background-color: $blue-darker;
        }
      }

      &.is-today {
        .DayContent {
          box-shadow: 0px 0px 0px 2px rgba($blue, 0.4), inset 0px 0px 0px 1px $white, 0px 0px 0px 3px $white;
        }
      }
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.CalendarTooltipIcon {
  display: inline-block;
  margin-left: $size-tiny;
  position: relative;
  top: -1px;
}

.CalendarGraphContent {
  // padding-top: $gutter;
}

