@import '../../css/essentials/all';

.Icon {
  vertical-align: middle;
  display: inline-block;
  width: $size;
  height: $size;
  color: inherit;

  &.is-tiny {
    width: $size-small;
    height: $size-small;
  }

  &.is-small {
    width: $size-medium;
    height: $size-medium;
  }
  
  &.is-large {
    width: $size-big;
    height: $size-big;
  }
}