.Image {
  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &.has-center-top {
    img {
      object-position: top center;
    }
  }
}