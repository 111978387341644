@import '../../css/essentials/all';

$size: $size-big;
$padding: $gutter*2;

.Navigation {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 100;

  @include until($desktop) {
    z-index: 200;
  }
  
  .container {
    padding: $padding;

    @include until($desktop) {
      padding: $size-small;
    }
  }

  .level {
    align-items: start;
  }
}


// MENU
.NavigationMenu {
  position: relative;
}

.NavigationMenuList {
  display: block;
  margin: 0;
  padding: 0;

  @include until($desktop) {
    display: flex;
    padding-top: $size-micro;
  }
}

.NavigationMenuListItem {
  display: block;
  color: rgba($white, 0.7);

  a {
    @include transition(color, $speed: $speed-fast);
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    line-height: $size-big;
    display: block;

    &:hover {
      color: $white;
    }

    &.is-active,
    &:active {
      color: $white;
      font-weight: $weight-bold;
    }

    @include until($desktop) {
      display: inline-block;
      margin-right: $gutter;
    }
  }
}

.NavigationMenuListGroup {
  .NavigationMenuListItem {
    padding-left: $size-tiny;
  }
}

.NavigationMenuListGroupHeader {
  text-transform: uppercase;
  color: rgba($white, 0.5);
  font-size: $size-smaller;
  font-weight: $weight-bold;
  margin-top: $size;
  margin-bottom: $size-tiny;
}

// ACTIONS

.NavigationActions {
  padding-top: $size-micro;
  padding-left: $size;
  
}

.NavigationActionsAdd {
  width: $size;
  height: $size;
  color: white;
  background: $blue;
  display: block;
  text-align: center;
  padding-top: $size-micro;
  border-radius: $size;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.21);
  @include transition();

  &:hover {
    background-color: lighten($blue, 10)
  }
}