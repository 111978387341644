.grid {
    display: grid;
    grid-gap: $size-medium;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: minmax(300px, auto);
    grid-auto-flow: dense;

    @include mobile {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        grid-auto-rows: minmax(260px, auto);
        grid-gap: $size-small;
    }
}

.grid-col-2 {
    grid-column-end: span 2;
}

.grid-row-2 {
    grid-row-end: span 2;
}

.grid-col-3 {
    grid-column-end: span 3;
}

.grid-row-3 {
    grid-row-end: span 3;
}

.grid > * {
  @include until($desktop) {
    grid-column-end: span 1;
    grid-row-end: span 1;
  }
}