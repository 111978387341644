@import '../../css/essentials/all';

// VARS
$bg: $white;

$action-bg: rgba($white, 0.3);
$action-bg-blurred: rgba($white, 0.2);

$notes-bg: #FAF9D3;
$notes-color: #45432E;

$radius: $radius-small;
$gutter: $size-medium;

// CSS

.Card {
  color: $text-color;
  background-color: $bg;
  border-radius: $radius;
  box-shadow: 0 1px $size-medium 0 rgba(29,35,40,0.4);
  position: relative;
  display: flex;
  flex-direction: column;

  &.is-dark {
    background-color: $dark;
  }
}


// HEADER

.CardHeader {
  padding: ($size-micro + $size-tiny) $gutter;
  text-align: center;
  font-weight: $weight-bold;
  position: relative;
  z-index: 10;
  @include shadow;
}


// CONTENT

.CardContent {
  padding: $gutter;
  flex: 1;
  overflow-y: auto;
  position: relative;
  z-index: 5;
}


// ACTION CARD

.Card.ActionCard {
  background-color: $action-bg;
  color: $white;
  overflow: hidden;

  .CardContent {
    padding: 0;
    overflow: hidden;
  }
}

@supports(backdrop-filter: blur()) {
  .Card.ActionCard {
    backdrop-filter: blur(13px);
    background-color: $action-bg-blurred;
    @include transition(backdrop-filter);
  }
}

.ActionCardItems {
  height: 100%;
  text-align: center;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  grid-auto-rows: minmax(1/4, 100%);
  grid-auto-flow: dense;
  
  .ActionCardItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-bottom: -1px;
    margin-right: -1px;
    @include transition(background);
  
    .ActionCardItemIcon {
      margin-bottom: $size-tiny;
    }

    .ActionCardItemLabel {
      color: rgba($white, 0.75);
    }

    &:hover {
      background: rgba($white, 0.1);
    }

    &:active {
      background: rgba($white, 0.2)
    }

    &:not(:nth-child(-n+2)) {
      border-top: 1px solid rgba($white, 0.075);
    }

    &:nth-child(odd) {
      border-right: 1px solid rgba($white, 0.075);
    }

    &:nth-child(odd):last-child {
      grid-column-end: span 2;
    }
  }
}





// NOTES CARD

.Card.NotesCard {
  background-color: $notes-bg;
  color: $notes-color;
}


// PHOTO CARD

.Card.PhotoCard {
  background-color: transparent;
  background-size: cover;
  overflow: hidden;

  .CardContent {
    padding: 0;
    border-radius: $radius;
  }

  .Image {
    width: 100%;
    height: 100%;
    position: relative;
    
    img {
      // border-radius: $radius;
    }
  }

  .PhotoCardLink {
    width: 100%;
    height: 100%;
    
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include transition(background-color);
    }

    &:hover {
      &:after {
        background-color: rgba($white, 0.1);
        // box-shadow: inset 0px 0px 0px $size-tiny rgba($white, 0.05);
      }
    }

  }
}

.Card.GraphCard {
}


// TIME

.Card.TimeCard {
  background-color: $action-bg;
  color: $white;

  &.is-dark {
    // color: $white;
  } 

  .TimeCardTimer {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: $weight-light;
    font-feature-settings: 'case' 1, 'tnum' 1;
  }

  .TimeCardDigit {
    display: inline-block;
    font-size: $size-large;
  }

  .TimeCardSeconds {
    animation: seconds 1s infinite $ease-in-out-quad;
  }
}

@supports(backdrop-filter: blur()) {
  .TimeCard {
    backdrop-filter: blur(13px);
    background-color: $action-bg-blurred;
    @include transition(backdrop-filter);
  }
}