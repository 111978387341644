@import '../../css/essentials/all';

$padding: $gutter*2;

.Site {
  // display: flex;
  padding: $padding $gutter;
  position: relative;
  z-index: 2;

  @include until($desktop) {
    padding: $gutter/2 $gutter/2;
  }
}

.SiteBg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  opacity: 0.3;
  height: 100vh;
}