@import "reset.scss";
@import "essentials/all.scss";
// IMPORTING MORE AT BOTTOM OF DOC
@import url('https://rsms.me/inter/inter-ui.css');
html { font-family: 'Inter UI', sans-serif; }

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-feature-settings: 'calt' 1;
  // font-feature-settings: 'calt' 1, 'case' 1, 'ss01' 1, 'ss02' 1;
}

@supports (font-variation-settings: normal) {
  html { font-family: 'Inter UI var alt', sans-serif; }
}

body {
  background: $body-background;
  color: $body-color;
  font-size: $text-size;
  line-height: $text-height;
  overflow: auto;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

svg {
  vertical-align: baseline;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

p {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: $size-small;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  list-style: none;

  &:not(:last-of-type) {
    margin-bottom: $size-small;
  }
}

.wrapper {
  min-height: 100vh;
  position: relative;
  
}

// IMPORTING MORE AT TOP OF DOC

@import "common/all.scss";
@import "utilities/all.scss";