$title-size: $text-size;
$title-weight: $weight-medium;
$title-color: $dark;
$title-color-light: $text-color-light;


// TITLES
$title-1: 2rem;
$title-2: 1.75rem;
$title-3: 1.5rem;
$title-4: 1.25rem;
$title-7: 0.8rem;

.title {
  font-size: $title-size;
  font-weight: $title-weight;
  color: $title-color;
  margin: 0;

  &.is-1 {
    font-size: $title-1;
  }
  &.is-2 {
    font-size: $title-2;
  }
  &.is-3 {
    font-size: $title-3;
  }
  &.is-7 {
    font-size: $title-7;
  }

  &.is-thin {
    font-weight: $weight-light;
  }
}

.subtitle {
  color: $grey;
}