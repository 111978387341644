.has-no-padding {
  padding: 0 !important;
}

.has-padding {
  padding: $gutter;
}

.has-text-left {
  text-align: left;
}

.block {
  display: block;
  
  &:not(:last-child) {
    margin-bottom: $gutter/2;
  }
}