@import '../../css/essentials/all';

.tippy-popper .light-theme {
  .tippy-content {
    text-align: left;
    color: $text-color;
    padding: $size-tiny $size-small;
    background: $white;
    border-radius: $radius;
    box-shadow: 0 1px 3px 0 rgba(1,20,35,0.12), 0 1px 14px 0 rgba(7,18,41,0.13);
  }
  .tippy-backdrop {
    background: transparent;
  }
}