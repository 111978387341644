@import '../../css/essentials/all';

$padding: $gutter*3;

.Main {
  flex: 1;
  opacity: 1;
  margin: 0 $padding*2 {
    right: $size-large*2;
  }
  z-index: 150;
  position: relative;

  @include until($desktop) {
    margin: 0;
    margin-top: $gutter*2;
  }
}